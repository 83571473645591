import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';

import bannerbg from '../../assets/img/breadcrumbs/as.jpg';


const ContactMain = () => {

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
        
            <div className="heading">
                <SiteBreadcrumb
                 pageTitle="DADAR MUMBAI (W)"
                
                breadcrumbsImg={bannerbg}
            /></div>
            
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address="
                                25, Bldg, G D Ambekar Rd, below Chanchal Smruti, opp. Shriram Industrial Estate, s;kdfhweilfhwe rfewobyefhdfhdsgfoudsfueawuodfgefheosihrpeajr, Maharashtra 400014"
                            />
                             
                        </div>
                        <div className="col-md-4">
                        <div className="container" >
                        
                               <div className="row y-middle">
                             
                                   <div className="map-canvas">
                                       <iframe src="https://maps.google.com/maps?q=readershub library dadar&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                   </div>
                               </div>
                               </div>
                               
                        </div>
                   
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                
                                phone="☏ -8390539987"          
                                
                                
                                
                            ></ContactInfo>
                        <span id="bhivandi-breadcrumb"></span>
                        </div>
                    </div>
                </div>
                
                </div>

            {/* Contact Section End */}
           

      {/* Your component's content here */}
   
  
             {/* SiteBreadcrumb */}
             <div className="heading">
             <SiteBreadcrumb 
             
                pageTitle="BHANDUP WEST MUMBAI (MH)"
                breadcrumbsImg={null}
            /></div>
             
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address="
                                Shop No 5, Rly Station, Ganga niwas, Bhandup Village Rd, near Tikitar Company, near Lal Bahadur Shastri Road, Bhandup, Subhash Nagar, Bhandup West, Mumbai, Maharashtra 400078"
                            />
                        </div>
                        <div className="col-md-4">
                        <div className="container" >
                        
                               <div className="row y-middle">
                                    <div className="map-canvas">
                                       <iframe src="https://maps.google.com/maps?q=readershub library Bhandup&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                   </div>
                               </div>
                               </div>
                               <span id="Kharghar-breadcrumb"></span>
                        </div>
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                phone="☏ -8087430891"
                                
                            ></ContactInfo>
                    
                        </div>
                    </div>
                </div>
               
                </div>

            {/* Contact Section End */}
           

            {/* SiteBreadcrumb */}
          
                 <SiteBreadcrumb
            

                pageTitle="Kharghar(Navi Mumbai)"
                pageName="Kharghar(Navi Mumbai)"
                breadcrumbsImg={null}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address="
                                2nd Floor, Railway Station, above Shiv Sena Office, near SN park, Sector 3, Belpada, Kharghar, Navi Mumbai, Maharashtra 410210"
                            />
                        </div>
                        <div className="col-md-4">
                        <div className="container" >
                        
                               <div className="row y-middle">
                             
                                   <div className="map-canvas">
                                       <iframe src="https://maps.google.com/maps?q=readershub library Kharghar&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                   </div>
                               </div>
                               </div>
                               
                        </div>
                        
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                
                                phone="☏ -073500 37443"          
                                
                                
                                
                            ></ContactInfo>
                    
                        </div>
                    </div>
                </div>
                <span id="Cotton-breadcrumb">knkn</span>
                </div>

            {/* Contact Section End */}
             {/* SiteBreadcrumb */}
             <div className="heading">
             <SiteBreadcrumb 
             
                pageTitle=" Cotton Green(W)"
                pageName=" Cotton Green(W)"
                breadcrumbsImg={null}

            /></div>
           
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address="
                                Zakeria Bundar Rd,Near Cotton Green Railway Station(W),
Mumbai- 400033"
                            />
                        </div>
                        <div className="col-md-4">
                        <div className="container" >
                        
                               <div className="row y-middle">
                                    <div className="map-canvas">
                                       <iframe src="https://maps.google.com/maps?q=readershub library Cotton Green&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                   </div>
                               </div>
                               </div>
                        </div>
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                phone="☏ - 091522 39519"
                                
                            ></ContactInfo>
                    
                        </div>
                    </div>
                </div>
                </div>
                

            {/* Contact Section End */}
            <span id="Thane-breadcrumb"></span>
                <div className="heading">
             <SiteBreadcrumb 
             
                pageTitle=" Thane(W)"
                pageName=" Thane(W)"
                breadcrumbsImg={null}

            /></div>
           
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address="
                                Ground Floor, Siddharth Tower Gokhale Road, Thane Station Rd, near Railway Station, Thane West, Thane, Maharashtra 400601"
                            />
                        </div>
                        <div className="col-md-4">
                        <div className="container" >
                        
                               <div className="row y-middle">
                                    <div className="map-canvas">
                                       <iframe src="https://maps.google.com/maps?q=readershub library Thane&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                   </div>
                               </div>
                               </div>
                        </div>
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                phone="☏ - 091569 05552"
                                
                            ></ContactInfo>
                    
                        </div>
                    </div>
                </div>
                </div>
                
                {/* Contact Section End */}

        </React.Fragment>


    );
}


export default ContactMain;